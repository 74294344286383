import useGetApiReq from '@/hooks/useGetApiReq';
import PartnerWrapper from '@/partner/components/wrapper/Wrapper';
import { useEffect, useState } from 'react'

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import ServiceRequestComp from '@/partner/components/service-request-comp/ServiceRequestComp';
import Spinner from '@/components/spinner/Spinner';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import ReactPaginate from 'react-paginate';
import ClientAndServiceComp from '@/partner/components/ClientAndservicecomp';
import { readCookie } from '@/utils/readCookie';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const navigate = useNavigate();

    const [allRequests, setAllRequests] = useState([]);
    const limit = import.meta.env.VITE_LIMIT;

    const getRecentRequests = async () => {
        fetchData(`/partner/get-all-requests?page=1&limit=${limit}`);
    };

    useEffect(() => {
        getRecentRequests();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("requests", res?.data);
            setAllRequests(res?.data?.requests);
        }
    }, [res]);

    const { res: assignedServiceRes, fetchData: fetchAssignedServiceData, isLoading: isAssignedServiceLoading } = useGetApiReq();
    const user = readCookie("user");
    const [services, setServices] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    const getAssignedServices = async () => {
        fetchAssignedServiceData(`/partner/get-assigned-services?partnerId=${user?._id}`);
    };

    useEffect(() => {
        getAssignedServices();
    }, [page]);

    useEffect(() => {
        if (assignedServiceRes?.status === 200 || assignedServiceRes?.status === 201) {
            console.log("services", assignedServiceRes)
            setServices(assignedServiceRes.data.assignedServices);
            setPageCount(assignedServiceRes.data.totalPages);
        }
    }, [assignedServiceRes]);

    return (
        <PartnerWrapper>
            <div>
                <h1 className='section-heading'>Recent Requests</h1>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead className="w-56">ServiceId</TableHead>
                            <TableHead>Client Name</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allRequests?.map((request) => (
                            <ServiceRequestComp
                                key={request._id}
                                serviceRequest={request}
                                getServiceRequests={getRecentRequests}
                            />
                        ))}
                    </TableBody>
                </Table>
                {allRequests.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allRequests.length === 0 && !isLoading &&
                    <DataNotFound name={"Requests"} />
                }

                <div className="section-heading-container mt-14">
                    <h2 className="text-xl font-semibold">Assigned Services</h2>
                    <button onClick={() => navigate("/partner/assigned-services")} className='bg-black ml-2 flex gap-3 items-center hover:bg-gray-700 text-white rounded-md px-4 py-1'>
                        View All
                    </button>
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Service Name</TableHead>
                            <TableHead>Service Cost</TableHead>
                            <TableHead>Client Name</TableHead>
                            <TableHead>Phone No</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Payment Status</TableHead>
                            {/* <TableHead>Actions</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isAssignedServiceLoading ? (
                            <TableRow>
                                <TableCell colSpan={6} className="text-center py-4">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : services.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <DataNotFound name={"Services"} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            services.map((service) => (
                                <ClientAndServiceComp
                                    key={service._id}
                                    service={service}
                                />
                            ))
                        )}
                    </TableBody>
                </Table>

                {/* {pageCount > 0 &&
                    <div className="mt-0 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>} */}
            </div>
        </PartnerWrapper>
    )
}

export default Dashboard