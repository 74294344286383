import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { AiFillDashboard } from "react-icons/ai";
import { MdFeedback, MdShoppingCart } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { BsChatSquareDotsFill } from "react-icons/bs";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaUserCog, FaUsers } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx";
import { readCookie } from "@/utils/readCookie";

const AdminSidebar = () => {
    const user = readCookie("user");
    const permissions = user?.permissions;
    const { pathname } = useLocation();

    return (
        <>
            <div className={`sidebar z-20 sticky top-[96px]`}>
                <ul className="ul">
                    {permissions?.dashboard !== "none" &&
                        <Link className={`link ${pathname.includes("dashboard") && "bg-[#a537e9]"}`} to={"/admin/dashboard"}>
                            Dashboard
                            <AiFillDashboard size={28} />
                        </Link>
                    }

                    {permissions?.categories !== "none" &&
                        <Link className={`link ${pathname.includes("categories") && "bg-[#a537e9]"}`} to={"/admin/categories"}>
                            Categories
                            <GrServices size={28} />
                        </Link>
                    }

                    {permissions?.serviceRequests !== "none" &&
                        <Link className={`link ${pathname.includes("serviceRequests") && "bg-[#a537e9]"}`} to={"/admin/serviceRequests"}>
                            Service Requests
                            <MdFeedback size={28} />
                        </Link>
                    }

                    {permissions?.partners !== "none" &&
                        <Link className={`link ${pathname.includes("partners") && "bg-[#a537e9]"}`} to={"/admin/partners"}>
                            Partners
                            <FaHandshakeSimple size={28} />
                        </Link>
                    }

                    {permissions?.clients !== "none" &&
                        <Link className={`link ${pathname.includes("clients") && "bg-[#a537e9]"}`} to={"/admin/clients"}>
                            Clients
                            <FaUsers size={28} />
                        </Link>
                    }

                    {/* {permissions?.serviceUpdates !== "none" &&
                        <Link className={`link ${pathname.includes("serviceUpdates") && "bg-[#a537e9]"}`} to={"/admin/serviceUpdates"}>
                            Service Update
                            <RxUpdate size={28} />
                        </Link>
                    } */}
                    {permissions?.purchasedServices !== "none" &&
                    <Link className={`link ${pathname.includes("purchased-services") && "bg-[#a537e9]"}`} to={"/admin/purchased-services"}>
                        Purchased Services
                        <MdShoppingCart size={28} />
                    </Link>
                    }

                    {permissions?.chats !== "none" &&
                        <Link className={`link ${pathname.includes("chats") && "bg-[#a537e9]"}`} to={"/admin/chats"}>
                            Chats
                            <BsChatSquareDotsFill size={24} />
                        </Link>
                    }

                    {permissions?.subAdmins !== "none" &&
                        <Link className={`link ${pathname.includes("subAdmins") && "bg-[#a537e9]"}`} to={"/admin/subAdmins"}>
                            Sub Admins
                            <FaUserCog size={24} />
                        </Link>
                    }
                </ul>
            </div>
        </>
    );
};

export default AdminSidebar;