import DocumentView from '@/client/components/document-view/DocumentView'
import { TableCell, TableRow } from '@/components/ui/table'
import useDeleteApiReq from '@/hooks/useDeleteApiReq'
import useGetApiReq from '@/hooks/useGetApiReq'
import { saveDoc } from '@/utils/download'
import axios from 'axios'
import { format } from 'date-fns'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { FaArrowAltCircleDown, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import AddServiceUpdateModal from '../AddServiceUpdateModal'
import DeleteAlert from '../DeleteAlert'

const ServiceUpdateComp = ({ update, getServiceUpdates }) => {
    const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);
    const [isUpdateServiceUpdateModalOpen, setIsUpdateServiceUpdateModalOpen] = useState(false);
    const [isDeleteServiceUpdateModalOpen, setIsDeleteServiceUpdateModalOpen] = useState(false);
    console.log("update", update);

    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: res2, fetchData: fetchData2, isLoading: isLoading2 } = useDeleteApiReq();

    const handleDelete = () => {
        fetchData2(`/partner/delete-service-update/${update?._id}`);
    }

    useEffect(() => {
        if (res2?.status === 200 || res2?.status === 201) {
            getServiceUpdates();
        }
    }, [res]);

    const getDoc = () => {
        fetchData(`/partner/get-doc?url=${update?.doc}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("Document", res);
            saveDoc(res?.data?.preSignedUrl);
        }
    }, [res])

    return (
        <>
            <TableRow>
                <TableCell>{`${update?.clientId?.name?.first} ${update?.clientId?.name?.last}`}</TableCell>
                {/* <TableCell className="w-40">
                    <span className={`px-2 py-[2px] rounded-md ${update?.status === "pending" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{update?.status}</span>
                </TableCell> */}
                <TableCell>{update?.createdAt && format(new Date(update?.createdAt), "dd/MM/yyyy")}</TableCell>
                <TableCell>
                    <button disabled={update?.doc ? false : true} className="cursor-pointer disabled:cursor-not-allowed" onClick={() => getDoc()}>
                        <FaArrowAltCircleDown size={22} cursor={"pointer"} />
                    </button>
                </TableCell>
                <TableCell>{update?.description && parse(update?.description)}</TableCell>
                <TableCell className="flex gap-3 items-center">
                    <FaEdit onClick={() => setIsUpdateServiceUpdateModalOpen(true)} size={22} cursor={"pointer"} />
                    <MdDelete onClick={() => setIsDeleteServiceUpdateModalOpen(true)} size={22} cursor={"pointer"} />
                </TableCell>
            </TableRow>

            <DocumentView
                document={update?.doc}
                setIsDocumentViewModalOpen={setIsDocumentViewModalOpen}
                isDocumentViewModalOpen={isDocumentViewModalOpen}
            />

            {isUpdateServiceUpdateModalOpen &&
                <AddServiceUpdateModal
                    isAddServiceUpdateModalOpen={isUpdateServiceUpdateModalOpen}
                    setIsAddServiceUpdateModalOpen={setIsUpdateServiceUpdateModalOpen}
                    getServiceUpdates={getServiceUpdates}
                    update={update}
                />
            }

            {isDeleteServiceUpdateModalOpen &&
                <DeleteAlert
                    handleDelete={handleDelete}
                    isDeleteModalOpen={isDeleteServiceUpdateModalOpen}
                    setIsDeleteModalOpen={setIsDeleteServiceUpdateModalOpen}
                />
            }
        </>
    )
}

export default ServiceUpdateComp