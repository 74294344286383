import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import logo from "@/assets/corporate-rasta-logo.png"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { format } from "date-fns"

import { Button } from "@/components/ui/button";
import html2PDF from "jspdf-html2canvas";

const InvoiceModal = ({ isInvoiceModalOpen, setIsInvoiceModalOpen, name,phone,email, address,invoiceId,invoiceDate, service,serviceCost }) => {

    const downloadInvoice = () => {
        html2PDF(document.querySelector("#invoice_box"), {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: './pdf/generate.pdf'
        });
    }

    return (
        <Dialog open={isInvoiceModalOpen} onOpenChange={() => setIsInvoiceModalOpen(!isInvoiceModalOpen)}>
            <DialogContent className="sm:max-w-[925px] h-[90vh] overflow-y-auto overflow-x-hidden">
                <div id="invoice_box" className="p-10 max-[560px]:p-2">
                    <div className="flex gap-2 items-center">
                        <img className="w-10 h-10 rounded-full" src={logo} alt="log0" />
                        <h2 className="text-2xl font-bold"> Corporate Rasta</h2>
                    </div>
                    <div>
                        <h2 className="text-2xl font-semibold mt-3 mb-2">Invoice</h2>
                        <p><span className="font-medium">Invoice No.: </span>#{invoiceId}</p>
                        <p className="mb-4"><span className="font-medium">Invoice Date: </span>{invoiceDate && format(new Date(invoiceDate), "dd/MM/yyyy")}</p>
                    </div>
                    <div className="flex max-[640px]:flex-col gap-4 mt-3">
                        <div className="w-[50%] max-[640px]:w-[100%] bg-[#E5E7EB] p-5 rounded-md">
                            <b className="font-semibold">From</b>
                            <h3 className="text-xl font-bold">Corporate Rasta</h3>
                            <p>Name</p>
                            <p>email@gmail.com</p>
                            <p>1234567890</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, nesciunt?</p>
                        </div>
                        <div className="w-[50%] max-[640px]:w-[100%] bg-[#E5E7EB] p-5 rounded-md">
                            <b className="font-semibold">To</b>
                            <h3 className="text-xl font-bold">{`${name?.first} ${name?.last}`}</h3>
                            <p>{email}</p>
                            <p>{phone}</p>
                            <p>{`${address?.addressLine}, ${address?.city}, ${address?.state}, ${address?.pinCode}`}</p>
                        </div>
                    </div>

                    <Table className="mt-4 mb-5 w-full">
                        <TableHeader>
                            <TableRow className="text-lg max-[560px]:text-sm">
                                <TableHead className="w-56">Service Id</TableHead>
                                <TableHead>Service Name</TableHead>
                                <TableHead>Price</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow className="max-[560px]:text-sm">
                                <TableCell className="w-56 break-all">{service?._id}</TableCell>
                                <TableCell>{service?.name} 1</TableCell>
                                <TableCell>₹{serviceCost}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <div className="flex justify-end">
                        <div>
                            {/* <p><span className="font-medium">SubTotal: </span>₹3400</p> */}
                            {/* <p><span className="font-medium">Tax Rate (18%): </span>₹600</p> */}
                            <p><span className="font-medium">Total: </span>₹{serviceCost}</p>
                        </div>
                    </div>

                </div>
                <div className="flex justify-end mt-3">
                    <Button className="bg-[#a537e9] hover:bg-[#a537e9]" onClick={downloadInvoice}>Download</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default InvoiceModal