import img from "@/assets/admin/admin.png"
import { useNavigate, useParams } from 'react-router-dom'

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import { FaEye } from 'react-icons/fa'
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import ServiceRequestComp from '@/admin/components/service-request-comp/ServiceRequestComp'
import { useCallback, useEffect, useState } from "react"
import useGetApiReq from "@/hooks/useGetApiReq"
import Spinner from "@/components/spinner/Spinner"
import DataNotFound from "@/components/data-not-found/DataNotFound"
import DeletePartnerModal from "@/admin/components/delete-partnerModal/DeletePartnerModal"
import { Button } from "@/components/ui/button"
import Rectangle17 from "@/assets/Rectangle 17.png";
import ReactPaginate from "react-paginate"
import ClientAndServiceComp from "@/admin/components/ClientAndservicecomp"

const PartnerDetails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const navigate = useNavigate();
    const params = useParams();

    const [clients, setClients] = useState([]);
    const [requests, setRequests] = useState([]);
    const [basicDetails, setBasicDetails] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    const getPartnerDetail = useCallback(async () => {
        fetchData(`/admin/get-partner-details/${params?.partnerId}`);
    }, [params.partnerId, fetchData]);

    useEffect(() => {
        getPartnerDetail();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("partner", res?.data);
            setBasicDetails(res?.data?.partner?.basicDetails);
            setClients(res?.data?.partner?.clients);
            setRequests(res?.data?.partner?.requests);
        }
    }, [res]);


    const { res: assignedServiceRes, fetchData: fetchAssignedServiceData, isLoading: isAssignedServiceLoading } = useGetApiReq();
    const [services, setServices] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    const getAssignedServices = async () => {
        fetchAssignedServiceData(`/admin/get-assigned-services?partnerId=${params?.partnerId}`);
    };

    useEffect(() => {
        getAssignedServices();
    }, [page]);

    useEffect(() => {
        if (assignedServiceRes?.status === 200 || assignedServiceRes?.status === 201) {
            console.log("services", assignedServiceRes)
            setServices(assignedServiceRes.data.assignedServices);
            setPageCount(assignedServiceRes.data.totalPages);
        }
    }, [assignedServiceRes]);

    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Basic Details</h1>
                    <div className="flex gap-2 items-center">
                        <Button onClick={() => setIsDeleteModalOpen(true)}>Delete Partner</Button>
                    </div>
                </div>
                <div className="border-2 rounded-md p-4 mt-4 flex items-start bg-[#F3F4F6] max-w-[550px] gap-5">
                    <div className="flex cursor-pointer flex-col gap-2">
                        <div className="w-32 h-36 rounded-lg">
                            <img className="w-full h-full" src={Rectangle17} alt="Rectangle17" />
                        </div>
                        <h3 className="text-xl text-center font-bold">{basicDetails?.name?.first}</h3>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <p className="text-[#6B7280] font-bold">Full Name</p>
                            <p>{`${basicDetails?.name?.first} ${basicDetails?.name?.last}`}</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-[#6B7280] font-bold">Phone No</p>
                            <p>{basicDetails?.phone}</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-[#6B7280] font-bold">Email</p>
                            <p>{basicDetails?.email}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <p className="text-[#6B7280] font-bold">Status</p>
                            <p>
                                <span className={`px-2 py-[2px] rounded-md ${basicDetails?.status ? "bg-green-400 text-white" : "bg-red-500 text-white"}`}>{basicDetails.status ? "Active" : "Blocked"}</span>
                            </p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-[#6B7280] font-bold">Address</p>
                            <p className="max-w-[200px]">{`${basicDetails?.address?.addressLine} ${basicDetails?.address?.city}, ${basicDetails?.address?.state}, ${basicDetails?.address?.pinCode}`}</p>
                        </div>
                    </div>
                </div>


                <div className='flex justify-between items-center gap-3 mb-3 mt-14'>
                    <h2 className="text-xl font-semibold">Clients</h2>
                    {clients.length > 0 &&
                        <button onClick={() => navigate(`/admin/partners/${params?.partnerId}/clients`, { state: clients })} className='bg-[#a537e9] hover:bg-[#67119c] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>

                <Table className="">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Name</TableHead>
                            <TableHead>Phone</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Address</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {clients?.map((client, index) => {
                            if (index <= 4) {
                                return (
                                    <TableRow key={client?._id} className="">
                                        <TableCell>{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
                                        <TableCell>{client?.phone}</TableCell>
                                        <TableCell>{client.email}</TableCell>
                                        <TableCell>{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</TableCell>
                                        <TableCell>
                                            <FaEye onClick={() => navigate(`/admin/clients/${client?._id}`)} size={24} cursor={"pointer"} />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {clients.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {clients.length === 0 && !isLoading &&
                    <DataNotFound name={"Clients"} />
                }



                <div className='flex justify-between items-center gap-3 mb-3 mt-14'>
                    <h2 className="text-xl font-semibold">Recent Requests</h2>
                    {requests.length > 0 &&
                        <button onClick={() => navigate(`/admin/partners/${params?.partnerId}/requests`, { state: requests })} className='bg-[#a537e9] hover:bg-[#67119c] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>


                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Client Name</TableHead>
                            <TableHead>Client Phone No</TableHead>
                            <TableHead>Service Cost</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {requests?.map((request, index) => {
                            if (index <= 4) {
                                return (
                                    <ServiceRequestComp
                                        key={request._id}
                                        serviceRequest={request}
                                        getServiceRequests={getPartnerDetail}
                                    />
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {requests.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {requests.length === 0 && !isLoading &&
                    <DataNotFound name={"Requests"} />
                }


                <div className="section-heading-container mt-14">
                    <h2 className="text-xl font-semibold">Assigned Services</h2>
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Service Name</TableHead>
                            <TableHead>Service Cost</TableHead>
                            <TableHead>Client Name</TableHead>
                            <TableHead>Phone No</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Payment Status</TableHead>
                            {/* <TableHead>Actions</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isAssignedServiceLoading ? (
                            <TableRow>
                                <TableCell colSpan={6} className="text-center py-4">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : services.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <DataNotFound name={"Services"} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            services.map((service) => (
                                <ClientAndServiceComp
                                    key={service._id}
                                    service={service}
                                />
                            ))
                        )}
                    </TableBody>
                </Table>

                {pageCount > 0 &&
                    <div className="mt-0 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}

                {isDeleteModalOpen &&
                    <DeletePartnerModal
                        status={basicDetails?.status}
                        getPartnerDetail={getPartnerDetail}
                        isDeleteModalOpen={isDeleteModalOpen}
                        setIsDeleteModalOpen={setIsDeleteModalOpen}
                    />
                }
            </div>
        </AdminWrapper>
    )
}

export default PartnerDetails