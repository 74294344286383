import { TableCell, TableRow } from '@/components/ui/table'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const ClientAndServiceComp = ({ service }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const navigate = useNavigate();

    return (
        <TableRow>
            <TableCell>
                <div onClick={() => navigate(`/partner/clients/${service?.clientId._id}/service/${service?._id}`, { state: { serviceId: service?.serviceId, isShowUpdateBtn: true } })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                    <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} alt="service" />
                </div>
            </TableCell>
            <TableCell className="font-medium">
                {service.serviceId?.name || 'N/A'}
            </TableCell>
            <TableCell className="font-medium">
                ₹{service?.cost || 'N/A'}
            </TableCell>
            <TableCell className="font-medium">
                {`${service.clientId?.name?.first} ${service.clientId?.name?.last}` || 'N/A'}
            </TableCell>
            <TableCell className="font-medium">
                {service.clientId?.phone || 'N/A'}
            </TableCell>
            <TableCell className="font-medium">
                {service.createdAt ? formatDate(service.createdAt) : 'N/A'}
            </TableCell>
            <TableCell className="w-40 text-center">
                <span className={`px-4 py-[2px] rounded-md ${service?.paymentStatus === "due" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{service?.paymentStatus}</span>
            </TableCell>
            {/* <TableCell className="font-medium">
                <button
                    className="px-2 rounded-md disabled:bg-gray-600 py-[2px] text-white bg-black hover:bg-gray-800 transition-colors"
                    onClick={() => {}}
                >
                    Accept
                </button>
            </TableCell> */}
        </TableRow>
    );
};

export default ClientAndServiceComp