import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import usePostApiReq from "@/hooks/usePostApiReq";
import { saveUser } from "@/store/slices/userSlice";

import Spinner from "@/components/spinner/Spinner";
import toast from "react-hot-toast";
import Rectangle2 from "@/assets/Rectangle 2.png";
import success from "@/assets/success.png";
import clientLoginBg from "@/assets/client-login-bg.png";
import clientLoginSm from "@/assets/client-login-sm.png";
import { readCookie } from "@/utils/readCookie";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Login = () => {
    const { res, fetchData, isLoading } = usePostApiReq()

    const [loginInfo, setLoginInfo] = useState({
        phone: "",
        password: "",
    });
    const [isShowPassword, setIsShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setLoginInfo({ ...loginInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!loginInfo.phone || !loginInfo.password) {
            toast.error("All fields are required");
            return;
        }

        fetchData("/client/login", { ...loginInfo });

    };

    useEffect(() => {
        (async () => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(saveUser({ user: res?.data?.user }));
                navigate("/client/dashboard");
            }
        })()
    }, [res])

    useEffect(() => {
        const user = readCookie("user");

        if (user) {
            if (user?.abc === "client") {
                navigate("/client/dashboard");
            } else if (user?.abc === "partner") {
                navigate("/partner/dashboard");
            } else if (user?.abc === "admin") {
                navigate("/admin/dashboard");
            }
        }
    }, [])


    return (
        <>
            <section className="flex items-center h-screen max-[770px]:hidden max-[770px]:flex-col-reverse bg-no-repeat bg-left min-[770px]:justify-between max-[770px]:justify-center max-[770px]:p-2" style={{ backgroundImage: `url(${clientLoginBg})` }}>
                <div className="max-w-[500px] w-full p-5 py-14 max-[770px]:p-2 max-[770px]:px-4 max-[770px]:ml-0 flex ml-40 flex-col justify-center items-center bg-[#FFFFFFF2] rounded-md">
                    <h1 className="signup-login-heading">
                        Client Login
                    </h1>
                    <form onSubmit={handleOnSubmit} className="form" action="">
                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="phone">
                                Phone
                            </Label>
                            <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={loginInfo.phone} name="phone" id="phone" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4 relative">
                            <Label htmlFor="password">
                                Password
                            </Label>
                            <Input type={isShowPassword ? "text" : "password"} placeholder="Enter Password" onChange={handleOnChange} value={loginInfo.password} name="password" id="password" className="col-span-3" />
                            {isShowPassword ?
                                <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                                : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            }
                        </div>
                        <div className="flex justify-end">
                            <Link to={"/client/forgot-password"} className="font-bold max-[435px]:text-sm">Forgot Password?</Link>
                        </div>
                        <div className="btn-container max-[770px]:mt-0 w-full">
                            <button className="btn w-full">{isLoading ? <Spinner /> : "Login"}</button>
                        </div>
                        <p className="text-center mt-2 max-[770px]:mt-0 max-[435px]:text-sm">Don't have account? <button type="button" onClick={() => navigate("/client/signup")} className="#892dc2">Signup</button></p>
                        <div className="flex gap-3 mt-3 max-[770px]:mt-0 justify-center">
                            <button type="button" onClick={() => navigate("/admin/login")} className="#892dc2 max-[435px]:text-sm">Admin Login</button>
                            <button type="button" onClick={() => navigate("/partner/login")} className="#892dc2 max-[435px]:text-sm">Partner Login</button>
                        </div>
                    </form>
                </div>
                <div className="flex justify-end">
                    <div className="login-signup-right-inner">
                        <img className="w-[75%] ml-auto max-[770px]:hidden" src={success} alt="success" />
                        <img className="w-[100%] mx-auto min-[770px]:hidden" src={clientLoginSm} alt="clientLoginSm" />
                    </div>
                </div>
            </section>
            <section className="flex items-center h-screen overflow-y-auto min-[770px]:hidden max-[770px]:flex-col-reverse bg-no-repeat bg-left min-[770px]:justify-between max-[770px]:justify-center max-[770px]:p-2">
                <div className="max-w-[500px] w-full p-5 py-14 max-[770px]:p-2 max-[770px]:px-4 max-[770px]:ml-0 flex ml-40 flex-col justify-center items-center bg-[#F3F4F6] rounded-md">
                    <h1 className="signup-login-heading">
                        Client Login
                    </h1>
                    <form onSubmit={handleOnSubmit} className="form" action="">
                        <div className="grid grid-cols-1 items-center gap-4 max-[770px]:gap-2">
                            <Label htmlFor="phone">
                                Phone
                            </Label>
                            <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={loginInfo.phone} name="phone" id="phone" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4 relative">
                            <Label htmlFor="password">
                                Password
                            </Label>
                            <Input type={isShowPassword ? "text" : "password"} placeholder="Enter Password" onChange={handleOnChange} value={loginInfo.password} name="password" id="password" className="col-span-3" />
                            {isShowPassword ?
                                <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                                : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            }
                        </div>
                        <div className="flex justify-end">
                            <Link to={"/client/forgot-password"} className="font-bold max-[435px]:text-sm">Forgot Password?</Link>
                        </div>
                        <div className="btn-container max-[770px]:mt-0 w-full">
                            <button className="btn w-full">{isLoading ? <Spinner /> : "Login"}</button>
                        </div>
                        <p className="text-center mt-2 max-[770px]:mt-0 max-[435px]:text-sm">Don't have account? <button type="button" onClick={() => navigate("/client/signup")} className="#892dc2">Signup</button></p>
                        <div className="flex gap-3 mt-3 max-[770px]:mt-0 justify-center">
                            <button type="button" onClick={() => navigate("/admin/login")} className="#892dc2 max-[435px]:text-sm">Admin Login</button>
                            <button type="button" onClick={() => navigate("/partner/login")} className="#892dc2 max-[435px]:text-sm">Partner Login</button>
                        </div>
                    </form>
                </div>
                <div className="flex justify-end">
                    <div className="login-signup-right-inner">
                        <img className="w-[75%] ml-auto max-[770px]:hidden" src={success} alt="success" />
                        <img className="w-[100%] mx-auto min-[770px]:hidden" src={clientLoginSm} alt="clientLoginSm" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;