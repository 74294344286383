import AddServiceModal from '@/admin/components/add-serviceModal/AddServiceModal'
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import Service from '@/admin/components/service/Service'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useCallback, useEffect, useState } from 'react'

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import ReactPaginate from 'react-paginate'
import { useParams } from 'react-router-dom'

const Services = () => {
  const { res, fetchData, isLoading } = useGetApiReq();
  
  const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const limit = import.meta.env.VITE_LIMIT;

  const params = useParams();

  const getServices = useCallback(async () => {
    fetchData(`/admin/get-services?categoryId=${params?.categoryId}&page=${page}&limit=${limit}`);
  }, [page, fetchData,params?.categoryId]);

  useEffect(() => {
    getServices();
  }, [page])

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setAllServices(res?.data?.services);
      const count = Math.ceil(res?.data?.count / limit)
      setPageCount(count);
    }
  }, [res])

  return (
    <AdminWrapper>
      <div className="section-heading-container">
        <h1 className='section-heading'>Services</h1>
        <Button className="bg-[#a537e9] hover:bg-[#67119c]" onClick={() => setIsAddServiceModalOpen(true)}>Add Service</Button>
      </div>
      <div className="">
        <Table className="mt-5">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Icon</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Actions</TableHead>
              <TableHead>Description</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {allServices?.map((service) => (
              <Service
                key={service._id}
                service={service}
                getServices={getServices}
              />
            ))}
          </TableBody>
        </Table>
        {allServices.length === 0 && isLoading &&
          <Spinner size={30} />
        }

        {allServices.length === 0 && !isLoading &&
          <DataNotFound name={"Services"} />
        }
      </div>

      {pageCount > 0 &&
        <div className="mb-5">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={(e) => setPage(e.selected + 1)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>}

      {isAddServiceModalOpen &&
        <AddServiceModal
          isAddServiceModalOpen={isAddServiceModalOpen}
          setIsAddServiceModalOpen={setIsAddServiceModalOpen}
          getServices={getServices}
        />
      }
    </AdminWrapper >
  )
}

export default Services