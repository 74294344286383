import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import useGetApiReq from '@/hooks/useGetApiReq'
import Spinner from '@/components/spinner/Spinner'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import parse from 'html-react-parser'
import { Button } from '@/components/ui/button'
import PurchasedServiceFilterModal from '@/admin/components/purchased-service-filterModal/PurchasedServiceFilterModal'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import Example from '@/partner/components/CmdkExample'

const PurchasedServicesPage = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: filterRes, fetchData: fetchFilterData, isLoading: isFilterLoading } = useGetApiReq();

    const navigate = useNavigate();
    const params = useParams();

    const [services, setServices] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [status, setStatus] = useState("");

    const limit = import.meta.env.VITE_LIMIT;


    const getClientDetail = useCallback(async () => {
        fetchData(`/admin/get-purchased-services-docs`);
    }, [fetchData, page]);

    useEffect(() => {
        getClientDetail();
    }, [page]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("purchased services", res?.data);
            setServices(res?.data?.services);
            // const count = Math.ceil(res?.data?.clientInfo.reqCount / limit)
            // setPageCount(count);
        }
    }, [res]);


    const filterPurchasedServices = useCallback(async () => {
        fetchData(`/admin/get-filtered-pucharesed-services?assignmentStatus=${status}`);
    }, [fetchFilterData]);

    useEffect(() => {
        if (status) {
            filterPurchasedServices();
        }
    }, [page, status]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("purchased services", res);
            setServices(res?.data?.services);
        }
    }, [res]);


    return (
        <AdminWrapper>
            <div className='flex justify-between items-center gap-3 mb-3'>
                <h2 className="text-xl font-semibold">Purchased Services</h2>
                <div className="flex gap-4 items-center">
                    <Select className="col-span-3" onValueChange={(value) => setStatus(value)} value={status} >
                        <SelectTrigger>
                            <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="assigned">Assigned</SelectItem>
                            <SelectItem value="unassigned">Unassigned</SelectItem>
                            <SelectItem value="completed">Completed</SelectItem>
                        </SelectContent>
                    </Select>
                    <Button onClick={() => setIsFilterModalOpen(true)}>Download</Button>
                </div>
            </div>

            <Table className="mt-5">
                <TableHeader className="bg-[#E5E7EB]">
                    <TableRow className="text-lg">
                        <TableHead>Icon</TableHead>
                        <TableHead>Service Name</TableHead>
                        <TableHead>Client Name</TableHead>
                        <TableHead>Category</TableHead>
                        <TableHead className="w-40">Payment Status</TableHead>
                        <TableHead>Description</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {services?.map((service, index) => {
                        return (
                            <TableRow key={service?._id}>
                                <TableCell>
                                    <div onClick={() => navigate(`/admin/clients/${service?.clientId._id}/service/${service?._id}`, { state: service?.serviceId })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                                        <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} alt="service" />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <h3 onClick={() => navigate(`/admin/clients/${service?.clientId._id}/service/${service?._id}`, { state: service?.serviceId })} className="text-lg cursor-pointer font-semibold">{service?.serviceId?.name}</h3>
                                </TableCell>
                                <TableCell>{`${service?.clientId?.name?.first} ${service?.clientId?.name?.last}`}</TableCell>
                                <TableCell>{service?.serviceId?.categoryId?.name}</TableCell>
                                <TableCell className="w-40 text-center">
                                    <span className={`px-4 py-[2px] rounded-md ${service?.paymentStatus === "due" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{service?.paymentStatus}</span>
                                </TableCell>
                                <TableCell className="w-80 line-clamp-2">{service?.serviceId?.description && parse(service?.serviceId?.description)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {services.length === 0 && isLoading &&
                <Spinner size={30} />
            }

            {services.length === 0 && !isLoading &&
                <DataNotFound name={"Services"} />
            }

            {isFilterModalOpen &&
                <PurchasedServiceFilterModal
                    isFilterModalOpen={isFilterModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                />
            }
        </AdminWrapper>
    )
}

export default PurchasedServicesPage