import { Button } from '@/components/ui/button'

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import useGetApiReq from '@/hooks/useGetApiReq'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const AssignPartnerModal = ({ isAssignClientModalOpen, setIsAssignClientModalOpen, clientService, getServiceDetail }) => {
    const frameworks = ["Next.js", "SvelteKit", "Nuxt.js", "Remix", "Astro"];

    const [open, setOpen] = useState(false);
    const [partnerId, setPartnerId] = useState("");
    const [search, setSearch] = useState("");
    const [selectedFramework, setSelectedFramework] = useState(null);
    const [allPartners, setAllPartners] = useState([]);
    const params = useParams();

    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: assignPartnerRes, fetchData: fetchAssignPartner, isLoading: isAssignPartnerLoading } = usePostApiReq();

    const filteredFrameworks = frameworks.filter((framework) =>
        framework.toLowerCase().includes(search.toLowerCase())
    );


    const getAllPartners = async () => {
        fetchData(`/admin/get-partner-list`);
    };

    useEffect(() => {
        getAllPartners();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("getAllPartners", res)
            setAllPartners(res?.data?.foundPartnersList);
        }
    }, [res]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!partnerId) {
            toast.error("All fields are required");
            return;
        }
        fetchAssignPartner(`/admin/assign-service-to-a-partner`, { partnerId, serviceClientLinkId: params?.serviceId });
    };

    useEffect(() => {
        if (assignPartnerRes?.status === 200 || assignPartnerRes?.status === 201) {
            console.log("assignPartnerRes", assignPartnerRes)
            setIsAssignClientModalOpen(false);
            getServiceDetail()
        }
    }, [assignPartnerRes]);

    return (
        <Dialog open={isAssignClientModalOpen} onOpenChange={setIsAssignClientModalOpen}>
            <DialogContent className="sm:max-w-[625px] max-h-[80vh] overflow-y-auto w-full">
                <DialogHeader>
                    <DialogTitle>Assign Partner</DialogTitle>
                </DialogHeader>

                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        {/* <Label>
                            Select Partner
                        </Label> */}
                        <Select onValueChange={(value) => setPartnerId(value)} value={partnerId} >
                            <SelectTrigger>
                                <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                                {/* <SelectGroup> */}
                                {allPartners.map((partner) => (
                                    <SelectItem key={partner?._id} value={partner?._id}>{`${partner?.name?.first} ${partner?.name?.last}`}</SelectItem>
                                ))}
                                {/* </SelectGroup> */}
                            </SelectContent>
                        </Select>

                        {/* <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    aria-expanded={open}
                                    className="w-full justify-between"
                                >
                                    {partnerId
                                        ? partnerId
                                        : "Select Partner..."}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-full">
                                <div className="p-2 bg-red-400 w-full">
                                    <Input
                                        placeholder="Search framework..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="mb-2"
                                    // autoFocus
                                    />
                                    <ul className="max-h-32 overflow-y-auto">
                                        {filteredFrameworks.map((framework) => (
                                            <li
                                                key={framework}
                                                onClick={() => {
                                                    setSelectedFramework(framework);
                                                    setOpen(false);
                                                }}
                                                className={`px-3 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 ${selectedFramework === framework ? "bg-gray-300 dark:bg-gray-800" : ""
                                                    }`}
                                            >
                                                {framework}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </PopoverContent>
                        </Popover> */}
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {/* {isLoading ? <Spinner /> : update ? "Update" : "Add"} */}
                            Submit
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog >
    )
}

export default AssignPartnerModal