import { useNavigate } from 'react-router-dom'
import "./category.css"
import { FaEdit } from 'react-icons/fa';
import { useState } from 'react';
import AddCategoryModal from '../add-categoryModal/AddCategoryModal';

const Category = ({ category, getCategories }) => {
    console.log("category", category);
    const navigate = useNavigate();
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);

    return (
        <>
            <div className="category relative overflow-hidden border-2 rounded-xl px-4 py-8 flex flex-col gap-3 items-center">
                <img className='absolute top-0 left-0 brightness-50 object-cover -z-10' src={`${import.meta.env.VITE_IMG_URL}/${category?.icon}`} alt="" />
                <FaEdit onClick={() => setIsEditCategoryModalOpen(true)} className='text-white absolute top-2 right-2 brightness-150 text-2xl cursor-pointer' />
                <h2 className="text-xl font-bold text-white text-center brightness-150">{category?.name}</h2>
                <button className="bg-[#a537e9] hover:bg-[#a537e9] rounded-md text-white px-4 py-1 max-[560px]:py-[2px] max-[560px]:px-2 max-[560px]:text-sm max-[560px]:rounded-3xl" onClick={() => navigate(`/admin/dashboard/category/${category?._id}`)}>View more</button>
            </div>

            {isEditCategoryModalOpen &&
                <AddCategoryModal
                    setIsAddCategoryModalOpen={setIsEditCategoryModalOpen}
                    isAddCategoryModalOpen={isEditCategoryModalOpen}
                    getCategories={getCategories}
                    category={category}
                />
            }
        </>
    )
}

export default Category