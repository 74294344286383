import SuccessModal from "@/client/components/successmodal/SuccessModal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import useGetApiReq from "@/hooks/useGetApiReq";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { readCookie } from "@/utils/readCookie";

const RequestService = ({ setIsRequestServiceModalOpen, isRequestServiceModalOpen, clientId, getClientDetail }) => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [serviceId, setServiceId] = useState("");

  const navigate = useNavigate();
  const user = readCookie("user");
  const [requirment, setRequirment] = useState("");


  const getServices = async () => {
    fetchServiceData(`/partner/get-client-unpucharesed-services?clientId=${clientId}`);
  }

  useEffect(() => {
    getServices();
  }, [])


  useEffect(() => {
    if (serviceRes?.status === 200 || serviceRes?.status === 201) {
      console.log("services", serviceRes);
      setAllServices(serviceRes?.data?.services);
    }
  }, [serviceRes])

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!requirment || !serviceId) {
      toast.error("All fields are required");
      return;
    }

    fetchData("/partner/request-service", { requirement: requirment, serviceId, clientId, partnerId: user?._id });

  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsRequestServiceModalOpen(false);
      getClientDetail();
      setIsSuccessModalOpen(true);
      // navigate("/client/chats", { state: { serviceId } });
    }
  }, [res])


  return (
    <>
      <Dialog open={isRequestServiceModalOpen} onOpenChange={() => setIsRequestServiceModalOpen(!isRequestServiceModalOpen)}>
        <DialogContent className="sm:max-w-[550px]">
          <DialogHeader>
            <DialogTitle>Request Service</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
            <div className="input-container">
              <Label htmlFor="serviceId">
                Services
              </Label>
              <Select className="col-span-3" name='serviceId' id="serviceId" onValueChange={(value) => setServiceId(value)} value={serviceId}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Service" />
                </SelectTrigger>
                <SelectContent className="max-h-[200px] overflow-y-auto">
                  {allServices?.map((service) => (
                    <SelectItem key={service?._id} value={service?._id}>{service?.name}</SelectItem>
                  ))}
                  {allServices.length === 0 && <p>No services available.</p>}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-1 items-center gap-4">
              <Label htmlFor="name">
                Requirement
              </Label>
              <ReactQuill theme="snow" value={requirment} onChange={setRequirment} />
            </div>
            <DialogFooter>
              <Button type="submit">Request</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
      />
    </>
  )
}

export default RequestService