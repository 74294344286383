import ServiceRequestComp from '@/partner/components/service-request-comp/ServiceRequestComp'
import img from "@/assets/admin/admin.png"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useGetApiReq from '@/hooks/useGetApiReq'
import Spinner from '@/components/spinner/Spinner'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import { Button } from '@/components/ui/button'
import PartnerWrapper from '@/partner/components/wrapper/Wrapper'
import parse from "html-react-parser"
import RequestService from '@/partner/components/request-service/RequestService'
import Partner from '@/partner/components/partner/Partner'
import ReactPaginate from 'react-paginate'
import Rectangle17 from "@/assets/Rectangle 17.png";

const ClientDetails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: res2, fetchData: fetchData2, isLoading: isLoading2 } = useGetApiReq();

    const navigate = useNavigate();
    const params = useParams();

    const [services, setServices] = useState([]);
    const [requests, setRequests] = useState([]);
    const [basicDetails, setBasicDetails] = useState("");
    const [isRequestServiceModalOpen, setIsRequestServiceModalOpen] = useState(false);
    const [status, setStatus] = useState("all");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    const limit = import.meta.env.VITE_LIMIT;


    const getClientDetail = async () => {
        fetchData(`/partner/get-client-details/${params?.clientId}?page=${page}&limit=${limit}`);
    };

    useEffect(() => {
        getClientDetail();
    }, [page]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("client details", res?.data);
            setBasicDetails(res?.data?.clientInfo?.basicDetails);
            setServices(res?.data?.clientInfo?.clientServices);
            setRequests(res?.data?.clientInfo?.requests);
            const count = Math.ceil(res?.data?.clientInfo.reqCount / limit)
            setPageCount(count);
        }
    }, [res]);



    const handleStatusChange = async () => {
        setRequests([]);
        if (status === "all") {
            getClientDetail();
        }
        else {
            fetchData2(`/partner/get-client-requests-by-status/${basicDetails?._id}?status=${status}&page=${page}&limit=${limit}`);
        }
    };

    useEffect(() => {
        handleStatusChange();
    }, [status, page]);

    useEffect(() => {
        if (res2?.status === 200 || res2?.status === 201) {
            console.log("res2", res2);
            setRequests(res2?.data?.requests);
            const count = Math.ceil(res2?.data?.count / limit)
            setPageCount(count);
        }
    }, [res2])

    return (
        <>
            <PartnerWrapper>
                <div className="section-heading-container">
                    <h2 className="text-2xl font-semibold mb-3">Basic Details</h2>
                    <div className='flex gap-2'>
                    <Button className="bg-[#a537e9] hover:bg-[#67119c]" onClick={() => setIsRequestServiceModalOpen(true)}>Request Service</Button>
                    <Button onClick={() => navigate(`/partner/clients/${params?.clientId}/drive`)}>Drive</Button>
                    </div>
                </div>
                <div className='flex justify-between flex-wrap items-center gap-5'>
                    {/* <div className="flex gap-20 items-center mt-5">
                        <div>
                            <img className='w-40 h-40 rounded-full' src={img} alt="partner" />
                        </div>
                        <div className="flex items-start gap-10">
                            <div className="flex flex-col max-w-60 gap-2">
                                <p><b>Name : </b>{`${basicDetails?.name?.first} ${basicDetails?.name?.last}`}</p>
                                <p><b>Phone : </b>{basicDetails?.phone}</p>
                                <p><b>Email : </b>{basicDetails?.email}</p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className='max-w-48'><b>Address : </b>{`${basicDetails?.address?.addressLine} ${basicDetails?.address?.city}, ${basicDetails?.address?.state}, ${basicDetails?.address?.pinCode}`}</p>
                                <p><b>Type : </b>{basicDetails?.type}</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="rounded-md p-4 mt-4 flex items-start gap-5">
                        <div className="flex flex-col gap-2">
                            <div className="w-32 h-36 rounded-lg">
                                <img className="w-full h-full" src={Rectangle17} alt="Rectangle17" />
                            </div>
                            <h3 className="text-xl text-center font-bold">{basicDetails?.name?.first}</h3>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Full Name</p>
                                <p>{`${basicDetails?.name?.first} ${basicDetails?.name?.last}`}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Phone No</p>
                                <p>{basicDetails?.phone}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Email</p>
                                <p>{basicDetails?.email}</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Address</p>
                                <p className="max-w-[200px]">{`${basicDetails?.address?.addressLine}, ${basicDetails?.address?.city}, ${basicDetails?.address?.state}, ${basicDetails?.address?.pinCode}`}</p>
                            </div>
                        </div>
                    </div>

                    {/* {basicDetails.type === "partner" &&
                        <div className=''>
                            <h2 className='text-lg font-medium mb-2'>Partner Details</h2>
                            <Partner partner={basicDetails.partnerId} />
                        </div>} */}
                </div>


                <div className='flex gap-x-20 justify-between items-center mb-3 mt-14 gap-3'>
                    <div className='flex gap-20'>
                        <h2 className="text-2xl font-semibold">Requests</h2>
                        <Select onValueChange={(value) => setStatus(value)} value={status}>
                            <SelectTrigger className="w-[180px] border-[#a537e9] text-[#a537e9]">
                                <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All</SelectItem>
                                <SelectItem value="pending">Pending</SelectItem>
                                <SelectItem value="accepted">Accepted</SelectItem>
                                <SelectItem value="quotation-sent">Quotation Sent</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    {requests.length > 0 &&
                        <button onClick={() => navigate(`/partner/clients/${params?.clientId}/requests`, { state: requests })} className='bg-[#a537e9] hover:bg-[#67119c] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }

                </div>

                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead className="w-56">ServiceId</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {requests?.map((request, index) => {
                            if (index <= 4) {
                                return (
                                    <ServiceRequestComp
                                        key={request._id}
                                        serviceRequest={request}
                                        getServiceRequests={getClientDetail}
                                    />
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {requests.length === 0 && (isLoading || isLoading2) &&
                    <Spinner size={30} />
                }

                {requests.length === 0 && !isLoading && !isLoading2 &&
                    <DataNotFound name={"Requests"} />
                }

                {pageCount > 0 &&
                    <div className="mt-10 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}


                <div className='flex justify-between items-center gap-3 mb-3 mt-14'>
                    <h2 className="text-2xl font-semibold">Purchased Services</h2>
                    {services.length > 0 &&
                        <button onClick={() => navigate(`/partner/clients/${params?.clientId}/purchased-services`, { state: services })} className='bg-[#a537e9] hover:bg-[#67119c] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>

                <Table className="mt-5">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Category</TableHead>
                            <TableHead>Description</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {services?.map((service, index) => {
                            if (index <= 4) {
                                return (
                                    <TableRow key={service?.serviceId?._id}>
                                        <TableCell>
                                            <div onClick={() => navigate(`/partner/clients/${params?.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                                                <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} alt="service" />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <h3 onClick={() => navigate(`/partner/clients/${params?.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="text-lg cursor-pointer font-semibold">{service?.serviceId?.name}</h3>
                                        </TableCell>
                                        <TableCell>{service?.serviceId?.categoryId?.name}</TableCell>
                                        <TableCell className="w-80 line-clamp-2">{service?.serviceId?.description && parse(service?.serviceId?.description)}</TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {services.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {services.length === 0 && !isLoading &&
                    <DataNotFound name={"Services"} />
                }


                {isRequestServiceModalOpen &&
                    <RequestService
                        isRequestServiceModalOpen={isRequestServiceModalOpen}
                        setIsRequestServiceModalOpen={setIsRequestServiceModalOpen}
                        clientId={basicDetails?._id}
                        getClientDetail={getClientDetail}
                    />}
            </PartnerWrapper>
        </>
    )
}

export default ClientDetails