import AddCategoryModal from '@/admin/components/add-categoryModal/AddCategoryModal';
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper';
import Category from '@/admin/components/category/Category';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import Spinner from '@/components/spinner/Spinner';
import { Button } from '@/components/ui/button';
import useGetApiReq from '@/hooks/useGetApiReq';
import { useCallback, useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";

const Categories = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const limit = import.meta.env.VITE_LIMIT;

    const getCategories = useCallback(async () => {
        fetchData(`/admin/get-categories?page=${page}&limit=${limit}`);
    }, [page, fetchData]);

    useEffect(() => {
        getCategories();
    }, [page])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllCategories(res?.data?.categories);
            const count = Math.ceil(res?.data?.count / limit)
            setPageCount(count);
        }
    }, [res])


    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Categories</h1>
                    <Button className="bg-[#a537e9] hover:bg-[#67119c]" onClick={() => setIsAddCategoryModalOpen(true)}>Add Category</Button>
                </div>

                {allCategories.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allCategories.length === 0 && !isLoading &&
                    <DataNotFound name={"Categories"} />
                }
                <div className='grid grid-cols-2 md:grid-cols-4 gap-6 mt-5'>
                    {allCategories?.map((category) => (
                        <Category
                            key={category._id}
                            category={category}
                            getCategories={getCategories}
                        />
                    ))}
                </div>
                {pageCount > 0 &&
                    <div className="mb-5 mt-0">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div>
            {isAddCategoryModalOpen &&
                <AddCategoryModal
                    setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
                    isAddCategoryModalOpen={isAddCategoryModalOpen}
                    getCategories={getCategories}
                />
            }
        </AdminWrapper>
    )
}

export default Categories