import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import "./category.css"

const Category = ({ category }) => {
  const navigate = useNavigate();

  return (
    <div className="category relative overflow-hidden border-2 rounded-xl px-4 py-8 flex flex-col gap-3 items-center">
      <img className='absolute top-0 left-0 brightness-50 object-cover w-full h-full -z-10' src={`${import.meta.env.VITE_IMG_URL}/${category?.icon}`} alt="" />
      <h2 className="text-xl font-bold text-white text-center brightness-150">{category?.name}</h2>
      <button className="bg-[#a537e9] hover:bg-[#7d2cb0] rounded-md text-white px-4 py-1 max-[560px]:py-[2px] max-[560px]:px-2 max-[560px]:text-sm max-[560px]:rounded-3xl" onClick={() => navigate(`/client/dashboard/${category?._id}`)}>View more</button>
    </div>
  )
}

export default Category