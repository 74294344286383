import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import useGetApiReq from '@/hooks/useGetApiReq'
import { readCookie } from '@/utils/readCookie'
import { useEffect, useState } from 'react'
import ClientAndServiceComp from '../components/ClientAndservicecomp'
import PartnerWrapper from '../components/wrapper/Wrapper'
import ReactPaginate from "react-paginate"
import DataNotFound from "@/components/data-not-found/DataNotFound"

const ClientsAndservices = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const user = readCookie("user");
    const [services, setServices] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    const getServiceDetail = async () => {
        fetchData(`/partner/get-assigned-services?partnerId=${user?._id}`);
    };

    useEffect(() => {
        getServiceDetail();
    }, [page]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("services", res)
            setServices(res.data.assignedServices);
            setPageCount(res.data.totalPages);
        }
    }, [res]);

    return (
        <PartnerWrapper>
            <div className="section-heading-container">
                <h1 className='section-heading'>Assigned Services</h1>
            </div>

            <Table className="mt-4">
                <TableHeader className="bg-[#E5E7EB]">
                    <TableRow className="text-lg">
                        <TableHead>Icon</TableHead>
                        <TableHead>Service Name</TableHead>
                        <TableHead>Service Cost</TableHead>
                        <TableHead>Client Name</TableHead>
                        <TableHead>Phone No</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead>Payment Status</TableHead>
                        {/* <TableHead>Actions</TableHead> */}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={6} className="text-center py-4">
                                Loading...
                            </TableCell>
                        </TableRow>
                    ) : services.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <DataNotFound name={"Services"} />
                            </TableCell>
                        </TableRow>
                    ) : (
                        services.map((service) => (
                            <ClientAndServiceComp
                                key={service._id}
                                service={service}
                            />
                        ))
                    )}
                </TableBody>
            </Table>

            {pageCount > 0 &&
                <div className="mt-0 mb-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </PartnerWrapper>
    )
}

export default ClientsAndservices