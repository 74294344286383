import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper';
import ServiceRequestComp from '@/admin/components/service-request-comp/ServiceRequestComp';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import Spinner from '@/components/spinner/Spinner';
import useGetApiReq from '@/hooks/useGetApiReq';
import React, { useCallback, useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import ReactPaginate from 'react-paginate';

const ServiceRequest = () => {
    const { res: res2, fetchData: fetchData2, isLoading: isLoading2 } = useGetApiReq();

    const [allRequests, setAllRequests] = useState([]);
    const [status, setStatus] = useState("all");
    const [type, setType] = useState("self");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const limit = import.meta.env.VITE_LIMIT;

    // const getServiceRequests = useCallback(async () => {
    //     fetchData(`/admin/get-all-requests?page=${page}&limit=${limit}`);
    // }, [page, fetchData]);

    useEffect(() => {
        // getServiceRequests();
        getServiceRequests(status);
    }, [page, type, status])


    const getServiceRequests = useCallback(() => {
        fetchData2(`/admin/get-requests?status=${status === "all" ? "" : status}&type=${type}&page=${page}&limit=${limit}`);

    }, [page, fetchData2, type, status]);

    useEffect(() => {
        if (res2?.status === 200 || res2?.status === 201) {
            console.log("requests res",res2);
            setAllRequests(res2?.data?.requests);
            const count = Math.ceil(res2?.data?.count / limit)
            setPageCount(count);
        }
    }, [res2])

    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Service Requests</h1>
                    <Select onValueChange={(value) => setType(value)} value={type}>
                        <SelectTrigger className="w-[180px] border-[#a537e9] text-[#a537e9]">
                            <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent>
                            {/* <SelectItem value="all">All</SelectItem> */}
                            <SelectItem value="self">Self</SelectItem>
                            <SelectItem value="partner">Partner</SelectItem>
                            <SelectItem value="admin">Admin</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className='flex gap-3 items-center mt-3'>
                    <button onClick={() => setStatus("all")} className={`px-2 py-1 rounded-md ${status !== "all" ? "bg-transparent border border-[#a537e9] text-[#a537e9]" : "bg-black text-white"}`}>All</button>
                    <button onClick={() => setStatus("pending")} className={`px-2 py-1 rounded-md ${status !== "pending" ? "bg-transparent border border-[#a537e9] text-[#a537e9]" : "bg-orange-400 text-white"}`}>Pending</button>
                    <button onClick={() => setStatus("accepted")} className={`px-2 py-1 rounded-md ${status !== "accepted" ? "bg-transparent border border-[#a537e9] text-[#a537e9]" : "bg-green-400 text-white"}`}>Accepted</button>
                    <button onClick={() => setStatus("quotation-sent")} className={`px-2 py-1 rounded-md ${status !== "quotation-sent" ? "bg-transparent border border-[#a537e9] text-[#a537e9]" : "bg-yellow-500 text-white"}`}>Quotation-sent</button>
                </div>

                <div className="">
                    <Table className="mt-4">
                        <TableHeader className="bg-[#E5E7EB]">
                            <TableRow className="text-lg">
                                <TableHead>Client Name</TableHead>
                                <TableHead>Client Phone No</TableHead>
                                <TableHead>Service Name</TableHead>
                                <TableHead>Service Cost</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Date</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {allRequests?.map((request) => (
                                <ServiceRequestComp
                                    key={request._id}
                                    serviceRequest={request}
                                    getServiceRequests={getServiceRequests}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    {allRequests.length === 0 && isLoading2 &&
                        <Spinner size={30} />
                    }

                    {allRequests.length === 0 && !isLoading2 &&
                        <DataNotFound name={"Requests"} />
                    }
                </div>


                {pageCount > 0 &&
                    <div className="mt-10 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div>
        </AdminWrapper>
    )
}

export default ServiceRequest