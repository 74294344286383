import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useGetApiReq from '@/hooks/useGetApiReq'
import ClientSelectSearchBox from '../client-select-search-box/ClientSelectSearchBox'
import ServiceSelectSearchBox from '../service-select-search-box/ServiceSelectSearchBox'
import usePatchApiReq from '@/hooks/usePatchApiReq'
import { useParams } from 'react-router-dom'
import AddFolderModal from '../add-folderModal/AddFolderModal'
import AddSubFolderModal from '../add-subFolderModal/AddSubFolderModal'
import Folder from '../folder/Folder'
import DataNotFound from '@/components/data-not-found/DataNotFound'

const options = ["Option 1", "Option 2", "Option 3", "Another Option"];

const AddServiceUpdateModal = ({ isAddServiceUpdateModalOpen, setIsAddServiceUpdateModalOpen, getServiceUpdates, serviceId, clientId, update }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePatchApiReq();
    const { res: clientRes, fetchData: fetchClientData, isLoading: isClientLoading } = useGetApiReq();
    const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();
    const { res: folderRes, fetchData: fetchFolderData, isLoading: isFolderLoading } = useGetApiReq();

    const params = useParams()

    const [serviceUpdateInfo, setServiceUpdateInfo] = useState({
        clientId: clientId || update?.client?._id || "",
        serviceId: update?.service?.serviceClientLinkId || params?.serviceId || "",
        status: update?.status || "",
        isDocument: update?.doc ? "true" : "false",
        document: "",
        preview: update?.doc && `${import.meta.env.VITE_IMG_URL}/${update?.doc}` || "",
    })
    const [desc, setDesc] = useState(update?.description || "");
    const [clients, setClients] = useState([]);
    const [services, setServices] = useState([]);

    const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);
    const [isAddSubSubFolderModalOpen, setIsAddSubSubFolderModalOpen] = useState(false);

    const [allFolders, setAllFolders] = useState([]);

    const [folderIdAndType, setFolderIdAndType] = useState({
        folderType: "",
        fId: "",
        folderName: ""
    });

    console.log("folderIdAndType", folderIdAndType);


    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setServiceUpdateInfo({
                    ...serviceUpdateInfo,
                    preview: this.result,
                    document: uploadedImage,
                });
            });
        }
    };

    useEffect(() => {
        if (serviceUpdateInfo.isDocument === "false") {
            setServiceUpdateInfo({ ...serviceUpdateInfo, document: "", preview: "" })
        }
    }, [serviceUpdateInfo.isDocument])

    const getClients = async () => {
        fetchClientData("/admin/get-clients?flag=no-paginate");
    }

    const getServices = async () => {
        fetchServiceData(`/admin/get-client-services/${serviceUpdateInfo?.clientId}`);
    }


    useEffect(() => {
        getClients();
    }, [])

    useEffect(() => {
        serviceUpdateInfo.clientId && getServices();
    }, [serviceUpdateInfo.clientId])


    useEffect(() => {
        if (clientRes?.status === 200 || clientRes?.status === 201) {
            setClients(clientRes?.data?.clients);
        }
    }, [clientRes])

    useEffect(() => {
        if (serviceRes?.status === 200 || serviceRes?.status === 201) {
            setServices(serviceRes?.data?.services);
        }
    }, [serviceRes])

    console.log("dkfdkfk",true + true);
    


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!serviceUpdateInfo.clientId
            || !serviceUpdateInfo.serviceId
            || (serviceUpdateInfo.isDocument === "true" && !serviceUpdateInfo.document)
            // || !serviceUpdateInfo.status
            || !desc
        ) {
            toast.error("All fields are required");
            return;
        }

        const formdata = new FormData();
        // formdata.append("clientId", serviceUpdateInfo.clientId);
        formdata.append("serviceClientLinkId", serviceUpdateInfo.serviceId);
        // formdata.append("status", serviceUpdateInfo.status);
        formdata.append("document", serviceUpdateInfo.document);
        formdata.append("description", desc);
        formdata.append("folderType", folderIdAndType.folderType);
        formdata.append("fId", folderIdAndType.fId);
        if (update) {
            formdata.append("serviceUpdateDocId", update?._id);
        }

        if (update) {
            fetchUpdateData("/admin/update-service-update", formdata);
        }
        else {
            fetchData("/admin/add-update-to-service", formdata);
        }
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddServiceUpdateModalOpen(false)
            getServiceUpdates();
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            setIsAddServiceUpdateModalOpen(false)
            getServiceUpdates();
        }
    }, [updateRes])


    const getFolders = () => {
        fetchFolderData(`/admin/get-folders/${params.clientId}`);
    }

    useEffect(() => {
        if (params.clientId) {
            getFolders();
        }
    }, [params.clientId])


    useEffect(() => {
        if (folderRes?.status === 200 || folderRes?.status === 201) {
            console.log("folders", folderRes);
            setAllFolders(folderRes?.data?.folders);
        }
    }, [folderRes])


    

    return (
        <>
            <Dialog open={isAddServiceUpdateModalOpen} onOpenChange={() => setIsAddServiceUpdateModalOpen(!isAddServiceUpdateModalOpen)}>
                <DialogContent className="sm:max-w-[625px] max-h-[80vh] overflow-y-auto w-full">
                    <DialogHeader>
                        <DialogTitle>Add Service Update</DialogTitle>
                    </DialogHeader>
                    <div className='flex justify-between gap-2 items-start'>
                        <div className='w-[250px]'>
                        {allFolders.length === 0 && !isFolderLoading &&
                    <DataNotFound name={"Folders"} />
                }
                            {allFolders?.map((folder) => (
                                <Folder
                                    key={folder?._id}
                                    folder={folder}
                                    folderIdAndType={folderIdAndType}
                                    setFolderIdAndType={setFolderIdAndType}
                                />
                            ))}
                        </div>

                        <div className='flex gap-2 items-center'>
                            {serviceUpdateInfo.serviceId && <Button onClick={() => setIsAddFolderModalOpen(true)}>Add Folder</Button>}
                            {/* {allFolders?.length > 0 && <Button onClick={() => setIsAddSubFolderModalOpen(true)}>Add Sub Folder</Button>} */}
                            {/* {<Button onClick={() => setIsAddSubSubFolderModalOpen(true)}>Add Sub's Sub Folder</Button>} */}
                        </div>
                    </div>
                    <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                        <div className="input-container">
                            <Label htmlFor="folder">
                                Folder
                            </Label>
                            <p>{folderIdAndType.fId ? folderIdAndType.folderName : "No folder selected.Please select folder."}</p>
                        </div>
                        {!clientId &&
                            <div className="input-container">
                                <Label htmlFor="clientId">
                                    Client
                                </Label>
                                <ClientSelectSearchBox
                                    clients={clients}
                                    setServiceUpdateInfo={setServiceUpdateInfo}
                                    name={update?.client?.name?.first}
                                />
                            </div>
                        }


                        {!serviceId && serviceUpdateInfo.clientId &&
                            <div className="input-container">
                                <Label htmlFor="serviceId">
                                    Service
                                </Label>
                                <ServiceSelectSearchBox
                                    services={services}
                                    setServiceUpdateInfo={setServiceUpdateInfo}
                                    name={update?.service?.name}
                                />
                            </div>}

                        <div className="input-container">
                            <Label htmlFor="desc">
                                Description
                            </Label>
                            <ReactQuill theme="snow" value={desc} onChange={setDesc} />
                        </div>
                        <div className="input-container">
                            <Label htmlFor="isDocument">
                                Upload Document
                            </Label>
                            <Select className="col-span-3" name='isDocument' id="isDocument" onValueChange={(value) => setServiceUpdateInfo({ ...serviceUpdateInfo, isDocument: value })} value={serviceUpdateInfo?.isDocument} >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="false">No</SelectItem>
                                    <SelectItem value="true">Yes</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        {serviceUpdateInfo.isDocument === "true" &&
                            <div className="input-container flex-col">
                                <Label htmlFor="document">
                                    Document
                                </Label>
                                <Input type="file" accept=".doc,.docx,.pdf" onChange={(e) => getImage(e)} name="document" id="document" />
                            </div>}
                        {serviceUpdateInfo.preview &&
                            <div className='w-full'>
                                <iframe className='w-full h-[350px] object-contain' src={serviceUpdateInfo.preview} alt="" />
                            </div>
                        }
                        <DialogFooter>
                            <Button type="submit">
                                {isLoading ? <Spinner /> : update ? "Update" : "Add"}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog >

            {isAddFolderModalOpen &&
                <AddFolderModal
                    isAddFolderModalOpen={isAddFolderModalOpen}
                    setIsAddFolderModalOpen={setIsAddFolderModalOpen}
                    getFolders={getFolders}
                    clientId={serviceUpdateInfo.clientId}
                    serviceClientLinkId={serviceUpdateInfo.serviceId}
                />
            }

        </>
    )
}

export default AddServiceUpdateModal