import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { FaBell } from "react-icons/fa"

import admin from "@/assets/admin.jpg"
import client from "@/assets/client.avif"
import partner from "@/assets/partner.avif"
import notificationNotFound from "@/assets/notification-not-found.png"

import useGetApiReq from "@/hooks/useGetApiReq"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import usePostApiReq from "@/hooks/usePostApiReq"
import Spinner from "@/components/spinner/Spinner"

const NotificationComp = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: res2, fetchData: fetchData2, isLoading: isLoading2 } = usePostApiReq();

    const navigate = useNavigate();
    const [allNotifications, setAllNotifications] = useState([]);
    const [notification, setNotification] = useState("");
    const [count, setCount] = useState(0);

    const getNotifications = async () => {
        fetchData("/partner/get-notifications");
    }

    useEffect(() => {
        getNotifications();
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllNotifications(res?.data?.notification?.notifications);
            setCount(res?.data?.notification?.unread);
            console.log("notifications =>", res);
        }
    }, [res])

    const handleOnClick = (notification, index) => {
        fetchData2("/partner/mark-as-read-notification", { index });
        setNotification(notification);
    }

    useEffect(() => {
        if (res2?.status === 200 || res2?.status === 201) {
            getNotifications();
            navigate(notification?.info?.navigate);
            console.log("notifications mark =>", res2);
        }
    }, [res2])

    return (
        <DropdownMenu>
            <DropdownMenuTrigger dir="right" className="relative">
                <FaBell size={24} cursor={"pointer"} />
                {count > 0 && <div className="w-5 h-5 bg-red-400 rounded-full flex justify-center items-center absolute top-1 -right-1">{count}</div>}
            </DropdownMenuTrigger>
            <DropdownMenuContent side="right" className="fixed -right-10 w-[300px] max-h-[300px] overflow-y-auto top-5 p-3">
                <DropdownMenuLabel>Notifications</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {allNotifications?.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allNotifications?.length === 0 && !isLoading &&
                    <div className="flex flex-col items-center text-center">
                        <img className="w-28 h-28" src={notificationNotFound} alt="notificationNotFound" />
                    </div>
                }
                {allNotifications?.map((notification, index) => (
                    <DropdownMenuItem key={notification?._id} className="mt-2">
                        <div onClick={() => handleOnClick(notification, index)} className="flex relative gap-2 items-center cursor-pointer">
                            <div className="w-9 h-9 cursor-pointer rounded-full">
                                <img className="w-full h-full rounded-full" src={admin} alt="admin" />
                            </div>
                            <div>
                                <div>{notification?.date && format(new Date(notification?.date), "dd, MMM, yyyy")}</div>
                                <p className="line-clamp-1 w-[250px] text-gray-600">{notification?.message}</p>
                            </div>
                            {!notification?.read && <div className="w-2 h-2 bg-red-400 rounded-full absolute top-4 -left-1"></div>}
                        </div>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default NotificationComp